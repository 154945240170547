@mixin headings() {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @content;
  }
}

// 14sp font
%std-font {
  // Default
  // font-size: .875rem;

  // LifeTodo
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
}
